<template>
  <div>
    <div
      v-if="!cover"
      class="c-form__group input-group"
    >
      <input
        :id="id"
        v-model="inputValue"
        type="text"
        class="c-form__control"
        name="video"
        placeholder="URL de la vidéo Youtube, Dailymotion ou Vimeo"
      />
      <span
        v-if="loading"
        class="input-loader"
      >
        <TheLoader />
      </span>
      <span
        v-if="error"
        class="c-form__feedback is-invalid"
      >
        {{ error }}
      </span>
    </div>
    <div
      v-else
      class="p-video"
    >
      <div class="p-video__url">
        {{ inputValue }}
        <span
          class="p-video__remove"
          aria-label="Supprimer la video"
          @click="removeVideo"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.407 15.053"
          >
            <path
              fill="#363636"
              d="M4.877 0a1.179 1.179 0 00-1.171 1.171v.5H.476a.5.5 0 00.052 1h10.694l-.324 11.19c0 .119-.066.183-.167.183H2.703c-.1 0-.164-.064-.167-.183l-.293-10.03a.5.5 0 10-1 .026l.293 10.03a1.194 1.194 0 001.171 1.16h8.028a1.194 1.194 0 001.171-1.16L12.23 2.67h.679a.5.5 0 100-1H9.727v-.5A1.179 1.179 0 008.56 0zm0 1H8.56a.157.157 0 01.167.167v.5H4.71v-.5A.157.157 0 014.877 1zM4.37 4.338a.5.5 0 00-.5.512v7.025a.5.5 0 101 0V4.85a.5.5 0 00-.507-.512zm2.342 0a.5.5 0 00-.5.512v7.025a.5.5 0 101 0V4.85a.5.5 0 00-.507-.512zm2.342 0a.5.5 0 00-.5.512v7.025a.5.5 0 101 0V4.85a.5.5 0 00-.507-.512z"
            />
          </svg>
        </span>
      </div>
      <iframe
        :src="cover"
        frameborder="0"
        class="p-video__iframe"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { string } from 'yup'
import type { ValidationError } from 'yup'
import { extractVideoId, buildEmbedUrl } from '~/utils/helpers'

const props = withDefaults(
  defineProps<{
    id?: string
    restricted?: boolean
  }>(),
  {
    id: 'videopreview',
    restricted: false,
  },
)

const inputValue = defineModel<string>({ default: '' })

watch(inputValue, () => onVideoUrlChange())

const error = ref<string>()
const urlSchema = string().url(`Cette URL n’est pas valide.`)
const loading = ref(false)
const cover = ref<string>()

const onVideoUrlChange = async () => {
  if (!inputValue.value) return

  error.value = ''

  try {
    await urlSchema.validate(inputValue.value)
  } catch (err) {
    error.value = (err as ValidationError).message

    return
  }

  const videoInfo = extractVideoId(inputValue.value)

  if (!videoInfo) {
    if (props.restricted) {
      error.value = 'Seules les vidéos Youtube sont autorisées.'
      return
    }
    error.value =
      'Seules les vidéos de Youtube, Dailymotion et Vimeo sont autorisées.'

    return
  }

  if (props.restricted && videoInfo.source !== 'youtube') {
    error.value = 'Seules les vidéos Youtube sont autorisées.'

    return
  }

  loading.value = true

  const linkPreview = useVideoLinkPreview(inputValue.value)
  const { data, error: crawlError } = await useFetch<{ error?: unknown }>(
    linkPreview,
  )

  if (crawlError.value || data.value?.error) {
    error.value = `Aucune information trouvée, veuillez vérifier l'URL`
    loading.value = false

    return
  }

  cover.value = buildEmbedUrl(videoInfo.source, videoInfo.id)
  loading.value = false
}

const removeVideo = () => {
  cover.value = undefined
  error.value = undefined
  inputValue.value = ''
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

.is-invalid {
  color: #ea5151;
}

.input {
  &-group {
    position: relative;
  }

  &-loader {
    position: absolute;
    top: (math.div(5rem, 2) - 1.5rem);
    right: 2rem;
  }
}

.p-video {
  &__url {
    position: relative;
    display: flex;
    align-items: center;
    height: 5rem;
    padding-left: 2rem;
    color: #fff;
    background-color: $dark;
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: #fff;
    transition: 0.3s opacity ease-in-out;

    &:hover {
      opacity: 0.9;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__iframe {
    width: 100%;
    height: 21rem;
  }
}
</style>
