<template>
  <div class="loader">
    <svg
      class="circular"
      viewBox="25 25 50 50"
    >
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  position: relative;
  display: grid;
  width: 30px;
  height: 30px;
  margin: 0 auto;

  .circular {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: $dark;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
